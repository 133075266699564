@use '@angular/material' as mat;
@import "variables";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
@include mat.core();

// Theme Init
@include mat.all-component-themes($unimatrix-dark-theme);
@include mat.all-component-densities(-2);
@include mat.form-field-density(-4);
@include mat.stepper-density(-4);
@include mat.paginator-density(-4);
@include mat.table-density(-2);
@include mat.chips-density(-2);
@include mat.list-density(0);
@include mat.tree-density(0);
@include mat.toolbar-density(-3);
@include mat.menu-density(-4);

// Apply the light theme when the document element attribute is changed to 'light'
:root[theme='light'] {
  // Use the `-color` mixins to only apply color styles without reapplying the same
  // typography and density styles.
  @include mat.all-component-colors($unimatrix-light-theme);
}
 
/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

main {
  height: calc(100vh - 3rem) !important;
  overflow: scroll;
  padding: 1.25rem;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

.unimatrix-footer{
  height: 50px;
}

.unimatrix-card {
  border-radius: 10px;
  padding: 1rem;
  overflow: hidden;
  min-height: 200px;
  margin-bottom: 20px;
}

th,td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rmv-field-padding {
  margin-bottom: -1.5em
}

.mat-mdc-form-field-subscript-wrapper {
  margin-bottom: -0.25em;
}

iframe {
  height: 100vh;
  width: 100%;
  border: 0;
}

.example-spacer {
  flex: 1 1 auto;
}

.tag {
  position: absolute;
  font-size: 18px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin-top: 10px;
}

mat-card {
  border-radius: 15px !important;
}

.mat-dialog-container {
  border-radius: 20px !important;
}

.mat-mdc-snack-bar-container {
  &.warn-snackbar {
    --mdc-snackbar-container-color: #bf3e3e;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
  &.success-snackbar {
    --mdc-snackbar-container-color: #00b247;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
  &.default-snackbar {
    --mdc-snackbar-container-color: #2e2e2e;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
}

.mat-mdc-paginator-range-label {
  margin: 0 5px 0 5px !important;
}

.unimatrix-text-badge {
  background-color: #7a7a7a50;
  font-size: 10px;
  padding: 2px 5px;
  border-radius: 10px;
}

.no-scroll {
  overflow: hidden;
}

.cdk-overlay-pane{
  .mat-mdc-card {
    border-radius: 0px !important;
    background-color: #21b65d !important;
  }
}

.opacity-60{
  opacity: 0.6;
}

.opacity-70{
  opacity: 0.7;
}

.ellipsis-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block; /* Ensure the span behaves like a block element */
  max-width: 100%;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}